import React, { useEffect } from "react";

const App = (props) => {
  useEffect(() => {
    window.location.href = "https://s0.sunokitaab.com/api/shared/share";
  }, []);
  return <></>;
};

export default App;
